<template>
  <v-app>
    <div
      style="
        background-image: url(/media/anjungan/BG-1280x1024.jpg);
        height: 100%;
        background-size: cover;
      "
    >
      <!-- Header::Start -->
      <div class="curved-header sample1">
        <div class="row px-10">
          <div class="col-1 pt-10">
            <img
              style="
                max-height: 198px;
                min-height: 198px;
                position: relative;
                top: 60px;
                left: 11px;
              "
              :src="config.city_logo"
              alt="LOGO DESA"
            />
          </div>
          <div class="col text-center pt-10 px-19">
            <h1 style="font-size: 54px; color: #1dac74">
              ANJUNGAN DESA MANDIRI
            </h1>
            <span
              style="font-size: 25px; text-align: justify; color: #3b4c42"
              >{{ config.address }}</span
            >
          </div>
        </div>
      </div>
      <!-- Header::End -->

      <!-- Menu::Start -->
      <div class="container mt-4" style="overflow: scroll; max-height: 560px">
        <!-- Breadcrumb::Start -->
        <h1 style="font-size: 35px; color: #bbc15d; font-weight: 600">
          <span @click="$router.push('/anjungan-mandiri/layanan-mandiri')">
            Layanan Mandiri</span
          >
          <span style="color: white"> > </span>
          <span @click="$router.push('/anjungan-mandiri/surat-menyurat')">
            Surat Menyurat</span
          >
          <span style="color: white"> > </span>
          <span style="font-size: 44px; color: white"> Kelahiran</span>
        </h1>
        <!-- Breadcrumb::End -->
        <div class="mt-auto" style="position: relative; top: 17px">
          <span style="font-size: 30px; color: white; font-weight: bold">
            Form Kelahiran</span
          >
          <b-form @submit.stop.prevent="formOnsubmit">
            <label
              for="input-hamlet_id"
              style="color: white; font-size: 22px; font-weight: 600"
              >Pilih No. WA Ketua RW:</label
            >
            <b-form-group id="input-group-hamlet_id">
              <treeselect
                v-model="form.hamlet_id"
                :multiple="false"
                placeholder="Pilih No. WA Ketua RW"
                :options="hamlets"
                style="font-size: 25px"
              />
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.hamlet_id }}</small
              >
            </b-form-group>
            <label
              for="input-id_card_number"
              style="color: white; font-size: 22px; font-weight: 600"
              >NIK Ayah:</label
            >
            <b-form-group id="input-group-id_card_number">
              <b-form-input
                id="input-id_card_number"
                v-model="IdCardNumber"
                style="font-size: 25px"
                placeholder="NIK"
                size="lg"
              ></b-form-input>
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.id_card_number }}</small
              >
            </b-form-group>
            <label
              for="input-family_number"
              style="color: white; font-size: 22px; font-weight: 600"
              >No. KK Ayah:</label
            >
            <b-form-group id="input-group-family_number">
              <b-form-input
                id="input-family_number"
                v-model="FamilyNumber"
                style="font-size: 25px"
                placeholder="No. KK"
                size="lg"
              ></b-form-input>
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.family_number }}</small
              >
            </b-form-group>
            <label
              for="input-resource_id_card_number"
              style="color: white; font-size: 22px; font-weight: 600"
              >NIK Ibu:</label
            >
            <b-form-group id="input-group-resource_id_card_number">
              <b-form-input
                id="input-resource_id_card_number"
                v-model="form.resource_id_card_number"
                style="font-size: 25px"
                placeholder="NIK"
                size="lg"
              ></b-form-input>
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.resource_id_card_number }}</small
              >
            </b-form-group>
            <label
              for="input-child_full_name"
              style="color: white; font-size: 22px; font-weight: 600"
              >Nama Lengkap Anak:</label
            >
            <b-form-group id="input-group-child_full_name">
              <b-form-input
                id="input-child_full_name"
                v-model="form.child_full_name"
                style="font-size: 25px"
                placeholder="Nama Lengkap Anak"
                size="lg"
              ></b-form-input>
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.child_full_name }}</small
              >
            </b-form-group>
            <label
              for="input-order"
              style="color: white; font-size: 22px; font-weight: 600"
              >Anak ke-:</label
            >
            <b-form-group id="input-group-order">
              <b-form-input
                id="input-order"
                v-model="form.order"
                style="font-size: 25px"
                placeholder="1/2/3/...dst"
                size="lg"
              ></b-form-input>
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.order }}</small
              >
            </b-form-group>
            <label
              for="input-child_gender"
              style="color: white; font-size: 22px; font-weight: 600"
              >Jenis Kelamin Anak:</label
            >
            <b-form-group id="input-group-child_gender">
              <b-form-radio
                v-model="form.child_gender"
                name="some-radios"
                value="male"
                size="lg"
                >Laki-laki</b-form-radio
              >
              <b-form-radio
                v-model="form.child_gender"
                name="some-radios"
                value="female"
                size="lg"
                >Perempuan</b-form-radio
              >
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.child_gender }}</small
              >
            </b-form-group>
            <label
              for="input-ttl"
              style="color: white; font-size: 22px; font-weight: 600"
              >Tempat, Tangal Lahir Anak:</label
            >
            <b-input-group>
              <div class="row mb-2">
                <div class="col">
                  <b-form-group
                    id="input-group-child_birth_place"
                    label=""
                    label-for="input-child_birth_place"
                  >
                    <b-form-input
                      id="input-child_birth_place"
                      v-model="form.child_birth_place"
                      style="font-size: 25px"
                      placeholder="Tempat"
                      size="lg"
                    ></b-form-input>
                    <small class="text-danger">{{
                      error.child_birth_place
                    }}</small>
                  </b-form-group>
                </div>
                <div class="col">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.child_birth_date"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-input-group>
                        <b-input-group-prepend>
                          <button
                            class="btn btn-secondary"
                            type="button"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fas fa-calendar"></i>
                          </button>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-trigger-modal-patient"
                          v-model="form.child_birth_date"
                          style="font-size: 25px"
                          placeholder="Tanggal (YYYY-MM-DD)"
                          readonly
                          size="lg"
                        >
                        </b-form-input>
                        <small class="text-danger">{{
                          error.child_birth_date
                        }}</small>
                      </b-input-group>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.child_birth_date"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.child_birth_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
            </b-input-group>
            <b-form-group id="input-group-child_address">
              <label
                for="input-child_address"
                style="color: white; font-size: 22px; font-weight: 600"
                >Alamat Anak:
              </label>
              <b-form-textarea
                id="input-child_address"
                v-model="form.child_address"
                style="font-size: 25px"
                placeholder="Alamat Anak"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.child_address }}</small>
            </b-form-group>

            <label
              for="input-birth_helper"
              style="color: white; font-size: 22px; font-weight: 600"
              >Penolong Kelahiran:
            </label>
            <b-form-group id="input-group-birth_helper">
              <b-form-input
                id="input-birth_helper"
                v-model="form.birth_helper"
                style="font-size: 25px"
                placeholder="Bidan / Dokter / Dukun"
                size="lg"
              ></b-form-input>
              <small class="text-danger">{{ error.birth_helper }}</small>
            </b-form-group>

            <label
              for="input-birth_helper_address"
              style="color: white; font-size: 22px; font-weight: 600"
              >Alamat Penolong Kelahiran:
            </label>
            <b-form-group id="input-group-birth_helper_address">
              <b-form-textarea
                id="input-birth_helper_address"
                v-model="form.birth_helper_address"
                style="font-size: 25px"
                placeholder="Alamat Penolong Kelahiran"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.birth_helper_address }}</small>
            </b-form-group>

            <b-button
              type="submit"
              size="lg"
              variant="white"
              style="
                height: 58px;
                width: 125px;
                font-size: 23px;
                font-weight: 600;
              "
              >Simpan</b-button
            >
            <b-button
              type="button"
              class="ml-2"
              style="
                background: #bbc15d;
                border-color: #bbc15d;
                height: 58px;
                width: 125px;
                font-size: 23px;
                font-weight: 600;
              "
              size="lg"
              @click="$router.push('/anjungan-mandiri/layanan-mandiri')"
            >
              Batal
            </b-button>
          </b-form>
        </div>
      </div>
      <!-- Menu::End -->
      <!-- Footer::Start -->
      <div class="curved-footer sample2">
        <div class="d-flex justify-content-center">
          <h1 style="font-size: 100px; font-weight: 600; color: #1dac74">
            {{ time }}
          </h1>
        </div>
        <div class="d-flex justify-content-center">
          <span style="color: #3b4c42; font-size: 30px" class="text-uppercase"
            >selamat datang di website desa {{ config.village_name }}</span
          >
        </div>
      </div>
      <!-- Footer::End -->
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      config: {},
      interval: null,
      time: null,
      form: {
        title: "Surat Keterangan Kelahiran",
        letter_type_id: 5,
      },
      error: {
        id_card_number: "",
        family_number: "",
        hamlet_id: "",
        resource_id_card_number: "",
        child_fullname: "",
        child_gender: "",
        order: "",
        child_address: "",
        child_birth_date: "",
        child_birth_place: "",
        birth_helper: "",
        birth_helper_address: "",
      },
      modal2: false,
      timeout: null,
      timeout2: null,
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    async getHamletHeadOption() {
      let response = await module.setTreeSelectHamletHead("hamlets");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.hamlets = response.data;
        this.hamlets.unshift({
          label: "Pilih No. WA Ketua Rw",
          id: "",
          isDisabled: true,
        });
      }
    },
    reset() {
      this.form.id_card_number = "";
      this.form.family_number = "";
      this.form.hamlet_id = "";
      //
      this.form.child_full_name = "";
      this.form.order = "";
      this.form.child_gender = "";
      this.form.child_address = "";
      this.form.resource_id_card_number = "";
      this.form.child_birth_date = "";
      this.form.child_birth_place = "";
      this.form.birth_helper = "";
      this.form.birth_helper_address = "";

      this.error.id_card_number = "";
      this.error.family_number = "";
      this.error.hamlet_id = "";
      //
      this.error.child_full_name = "";
      this.error.order = "";
      this.error.child_gender = "";
      this.error.child_address = "";
      this.error.resource_id_card_number = "";
      this.error.child_birth_date = "";
      this.error.child_birth_place = "";
      this.error.birth_helper = "";
      this.error.birth_helper_address = "";
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(
        this.form,
        "birth-certificate-no-auth"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.reset();
      }
    },
    async checkIdCardNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(this.form, "check-id-card-number");
      // Check Response
      if (response.state == "error") {
        Swal.fire("", "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.", "error");
        this.error.id_card_number = "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan."
      } else {
        this.error.id_card_number = ""
      }
    },
    async checkFamilyNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(this.form, `check-compatibility/${this.form.id_card_number}/${this.form.family_number}`);
      console.log('resp', response)
      // Check Response
      if (response.success == "") {
        this.error.family_number = "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda."
        Swal.fire("", "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.", "error");
      } else {
        this.error.family_number = ""
      }
    },
  },
  mounted() {
    this.getSetting();
    this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
  computed: {
    IdCardNumber: {
      get() {
        this.error.id_card_number = ''
        return this.form.id_card_number;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.form.id_card_number = val;
          this.checkIdCardNumber();
        }, 500);
      },
    },
    FamilyNumber: {
      get() {
        this.error.family_number = ''
        return this.form.family_number;
      },
      set(val) {
        if (this.timeout2) clearTimeout(this.timeout2);
        this.timeout2 = setTimeout(() => {
          this.form.family_number = val;
          this.checkFamilyNumber();
        }, 500);
      },
    },
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 172px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -4px;
  width: 100%;
}

.form-control-lg {
  height: 61px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}

.form-group label {
  font-size: 2rem;
  font-weight: 400;
  color: #ffffff;
}

.vue-treeselect__label {
    color: black !important;
}
</style>